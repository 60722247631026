<template>
     <div class="project body">
          <div class="container ">
               <div class="head">
                    <div class="columns">
                         <div class="column is-5">
                              <div class="text" data-aos="fade-right" data-aos-duration="1000">
                                   <div class="section-title-3">
                                        Hospitality | 2015
                                   </div>
                                   <div class="section-header-3">
                                        GRAND HYATT,<br />
                                        KOCHI
                                   </div>

                                   <div class="mt-10">
                                        <p class="small">
                                             Execution of I , Data Center and ELV Systems which includes, Data , IPBX, Switching, IP Surveillance,
                                             ProWatch Access Control System, Milestone , Video Management Solutions, etc.
                                        </p>
                                   </div>
                              </div>
                         </div>
                         <div class="column ">
                              <div class="image" data-aos="fade-left" data-aos-duration="1000">
                                   <img src="@/assets/images/hyatt.png" alt="" />
                              </div>
                         </div>
                    </div>
               </div>

               <div class="content">
                    <div class="columns ">
                         <div class="column  ">
                              <div class="line-bottom reverse-on-mobile">
                                   <div class="text ">
                                        <p class="small">
                                             Grand Hyatt, Kochi is a flagship property of Lulu Group International and it has been rated as one of the
                                             best hospitality properties in Kerala, with 5 star luxury hotel and largest International convention
                                             centre in South India.
                                        </p>

                                        <p class="small">
                                             The Infrastructure consists of 280 rooms which includes Presidential suites, Executive suites, Owner
                                             suites and Guest suite rooms, 4 private villas with plunge pools, Grand Ballrooms, Junior Ballrooms,
                                             Pre-function, Function Lawn, Board Rooms, EAM, Retail Shops, Business Centres, Manager’s Apartments, Spa,
                                             Gym, Grand Club Lounge, external and internal swimming pools.
                                        </p>
                                   </div>
                              </div>
                         </div>
                         <div class="column  imgcol">
                              <img src="@/assets/images/hyatt-1.png" alt="" />
                         </div>
                    </div>
                    <div class="columns reverse-columns  ">
                         <div class="column imgcol">
                              <img src="@/assets/images/hyatt-2.png" alt="" />
                         </div>
                         <div class="column ">
                              <div class="line-bottom reverse-on-mobile">
                                   <div class="text ">
                                        <p class="small">
                                             Grand Hyatt, Kochi is a flagship property of Lulu Group International and it has been rated as one of the
                                             best hospitality properties in Kerala, with 5 star luxury hotel and largest International convention
                                             centre in South India.
                                        </p>

                                        <p class="small">
                                             The Infrastructure consists of 280 rooms which includes Presidential suites, Executive suites, Owner
                                             suites and Guest suite rooms, 4 private villas with plunge pools, Grand Ballrooms, Junior Ballrooms,
                                             Pre-function, Function Lawn, Board Rooms, EAM, Retail Shops, Business Centres, Manager’s Apartments, Spa,
                                             Gym, Grand Club Lounge, external and internal swimming pools.
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div class="services ">
                    <div class="section-header">
                         We have executed the following services in the project:
                    </div>

                    <div class="listcontainer">
                         <div class="list">
                              <div class="section-title-3">
                                   Information & Communication Technology
                                   <button class="button plusbtn" @click="toggleOpen(0)">
                                        <svg
                                             id="Group_40"
                                             data-name="Group 40"
                                             xmlns="http://www.w3.org/2000/svg"
                                             width="10.675"
                                             height="10.675"
                                             viewBox="0 0 10.675 10.675"
                                        >
                                             <line id="Line_27" data-name="Line 27" x2="10.675" transform="translate(0 5.337)" stroke-width="0.5" />
                                             <line
                                                  v-if="!show[0]"
                                                  id="Line_28"
                                                  data-name="Line 28"
                                                  x1="10.675"
                                                  transform="translate(5.337 0) rotate(90)"
                                                  stroke-width="0.5"
                                             />
                                        </svg>
                                   </button>
                              </div>

                              <div class="line-bottom">
                                   <transition name="fade">
                                        <ul class="square-icon" v-if="show[0]">
                                             <li>Structured cabling w.r.t the ELV Services</li>
                                             <li>Wired and Wireless network Services</li>
                                             <li>Data, Voice and Video Network.</li>
                                             <li>IPBAX with Voice over IP Services</li>
                                             <li>IPTV Systems</li>
                                             <li>Data centre.</li>
                                             <li>Digital signages.</li>
                                             <li>Internet Distribution Facility.</li>
                                             <li>Network devices, Routers, Switches and Firewalls.</li>
                                        </ul></transition
                                   >
                              </div>
                         </div>

                         <div class="list">
                              <div class="section-title-3">
                                   Infrastructure Management Centers
                                   <button class="button plusbtn" @click="toggleOpen(1)">
                                        <svg
                                             id="Group_40"
                                             data-name="Group 40"
                                             xmlns="http://www.w3.org/2000/svg"
                                             width="10.675"
                                             height="10.675"
                                             viewBox="0 0 10.675 10.675"
                                        >
                                             <line id="Line_27" data-name="Line 27" x2="10.675" transform="translate(0 5.337)" stroke-width="0.5" />
                                             <line
                                                  v-if="!show[1]"
                                                  id="Line_28"
                                                  data-name="Line 28"
                                                  x1="10.675"
                                                  transform="translate(5.337 0) rotate(90)"
                                                  stroke-width="0.5"
                                             />
                                        </svg>
                                   </button>
                              </div>

                              <div class="line-bottom">
                                   <transition name="fade">
                                        <ul v-if="show[1]" class="square-icon">
                                             <li>Datacentre</li>
                                             <li>Command and Control Centre</li>
                                             <li>Network Operating Centres</li>
                                        </ul></transition
                                   >
                              </div>
                         </div>

                         <div class="list">
                              <div class="section-title-3">
                                   Electronic Security Systems
                                   <button class="button plusbtn" @click="toggleOpen(2)">
                                        <svg
                                             id="Group_40"
                                             data-name="Group 40"
                                             xmlns="http://www.w3.org/2000/svg"
                                             width="10.675"
                                             height="10.675"
                                             viewBox="0 0 10.675 10.675"
                                        >
                                             <line id="Line_27" data-name="Line 27" x2="10.675" transform="translate(0 5.337)" stroke-width="0.5" />
                                             <line
                                                  v-if="!show[2]"
                                                  id="Line_28"
                                                  data-name="Line 28"
                                                  x1="10.675"
                                                  transform="translate(5.337 0) rotate(90)"
                                                  stroke-width="0.5"
                                             />
                                        </svg>
                                   </button>
                              </div>

                              <div class="line-bottom">
                                   <transition name="fade">
                                        <ul v-if="show[2]" class="square-icon">
                                             <li>Video Surveillance System.</li>
                                             <li>Access control Systems.</li>
                                             <li>Intrusion Detection and Control System.</li>
                                             <li>Vehicle entry control systems (Barriers, Blocking Bollards, UVSS, etc.)</li>
                                             <li>Scanner (XBS, etc.)</li>
                                             <li>Gate and Perimeter Control Systems.</li>
                                        </ul>
                                        </transition>
                              </div>
                         </div>
                    </div>
               </div>

               <div class="more">
                    <div class="columns">
                         <div class="column">
                              <p class="small">
                                   <router-link :to="{name:'HOSPITALITY'}">View more Hospitality Projects ></router-link>
                              </p>
                         </div>
                         <div class="column">
                              <p class="small">
                                   <router-link :to="{name:'PROJECTS'}">View all Projects ></router-link>
                              </p>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {
          data() {
               return {
                    show: [true, false, false],
               };
          },
          methods: {
               toggleOpen(index) {
                    this.show.splice(index, 1, !this.show[index]);
               },
          },
     };
</script>

<style lang="scss" scoped>
     
</style>
